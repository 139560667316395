import { createApp } from "vue";
import App from "./App.vue";
import { installi18n } from "./lang/lang.js";
import { createPinia } from "pinia";
import { createGtm } from "@gtm-support/vue-gtm";
import router from "./router.js";
import "core-js/actual/json";
import { Decimal } from "decimal.js-light";

import "./assets/sass/app.scss";
// import "./polyfills";
import * as CanvasJS from "./assets/js/canvasjs.min.js";
window.CanvasJS = window.CanvasJS || CanvasJS;

// Layouts
import createLayouts from "./layouts/_globals.js";

// Components
import createComponents from "./components/_globals.js";

// Directives
import createDirectives from "./directives/_globals.js";

// Filters
import createFilters from "./filters/_globals.js";

// Plugins
import createPlugins from "./plugins/_globals.js";

// get client name to disable GTM for Siemens
import { useClientName } from "@/plugins/client-name/client-name.plugin.js";
const { clientName } = useClientName();

// Add axios interceptor
// @todo: create a plugin to handle this
import axiosSetup from "./helpers/axios.helper.js";
async function configureAxios() {
	try {
		await axiosSetup();
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error("failed setting up axios", err);
	}
}
configureAxios();

const pinia = createPinia();

BigInt.prototype.toJSON = function () {
	return JSON.rawJSON(this.toString());
};

Decimal.prototype.toJSON = function () {
	return JSON.rawJSON(this.toString());
};

const app = createApp(App)
	.use(createPlugins)
	.use(installi18n)
	.use(createComponents)
	.use(createLayouts)
	.use(createDirectives)
	.use(createFilters)
	.use(pinia)
	.use(router);

import FontAwesomeIcon from "@/components/font-awesome-icon/font-awesome-icon.vue";
app.component("FontAwesomeIcon", FontAwesomeIcon); // temporary just to make sure we are no longer using font-awesome-icon indeed

if (clientName !== "siemens") {
	// this should be placed on plugins, but because of router, it's simple easier to leave it here
	app.use(
		createGtm({
			id: import.meta.env.VITE_GTM_ID,
			// enabled: true,
			enabled:
				import.meta.env.PROD &&
				import.meta.env.VITE_DATADOG_ENV === "prod",
			debug: import.meta.env.DEV,
			vueRouter: router,
			ignoredViews: ["login"],
			defer: true
		})
	);
}

// Init app after routes being loaded
router.isReady().then(() => app.mount("#app"));

export default app;
