import axios from "axios";

const API = import.meta.env.VITE_API;

export default {
	deviceId: null,

	setDeviceId(deviceId) {
		this.deviceId = deviceId;
	},

	async sendCommand(command, { commandArguments = [], deviceId = null }) {
		// all commands have changed to be POST instead of PUT
		return axios
			.post(`${API}/devices/${deviceId || this.deviceId}/commands`, {
				command: command,
				arguments: commandArguments
			})
			.then((resp) => resp.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getAllCommands(deviceId = null, params = {}) {
		return axios
			.get(`${API}/devices/${deviceId || this.deviceId}/commands`, {
				params
			})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getCommand(commandId, deviceId = null, params = {}) {
		return axios
			.get(
				`${API}/devices/${
					deviceId || this.deviceId
				}/commands/${commandId}`,
				{
					params
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	}
};
