<template>
	<div>
		<span v-if="isPending">
			<p>
				{{ $t("banner.scaling-to-gateway.modbus.body.pending.1") }}
			</p>
			<br />
			<p>
				<i18n-t
					keypath="banner.scaling-to-gateway.modbus.body.pending.2"
					tag="p"
					scope="global"
				>
					<template #ctaBtnText>
						{{
							$t(
								"banner.scaling-to-gateway.modbus.start-migration-btn"
							)
						}}
					</template>
					<template #downloadBtn>
						<ws-text-link
							is-link
							:show-icon="false"
							@click="emits('downloadProperties')"
						>
							{{
								$t(
									"banner.scaling-to-gateway.modbus.download-properties-btn"
								)
							}}
						</ws-text-link>
					</template>
				</i18n-t>
			</p>
			<p>
				{{ $t("banner.scaling-to-gateway.modbus.body.pending.3") }}
			</p>
			<br />
			<p>
				{{ $t("banner.scaling-to-gateway.modbus.body.pending.4") }}
			</p>

			<div class="mt-4 mb-0 buttons">
				<ws-button
					is-grey
					is-small
					is-outlined
					@click="emits('startMigration')"
				>
					{{
						$t(
							"banner.scaling-to-gateway.modbus.start-migration-btn"
						)
					}}
				</ws-button>
			</div>
		</span>
		<span v-if="isQueued">
			<p v-html="$t('banner.scaling-to-gateway.modbus.body.queued')"></p>
			<div class="mt-4 mb-0 buttons">
				<!-- <ws-button
					is-grey
					is-small
					is-outlined
					@click="emits('revertMigration')"
				>
					{{
						$t(
							"banner.scaling-to-gateway.modbus.revert-migration-btn"
						)
					}}
				</ws-button> -->
				<ws-button
					is-grey
					is-small
					is-outlined
					@click="emits('sendConfiguration')"
				>
					{{
						$t(
							"banner.scaling-to-gateway.modbus.send-configuration-btn"
						)
					}}
				</ws-button>
			</div>
		</span>
	</div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emits = defineEmits([
	"downloadProperties",
	"startMigration",
	"revertMigration",
	"sendConfiguration"
]);

const props = defineProps({
	task: {
		type: Object,
		default: () => {}
	}
});

const isPending = computed(() => props.task?.status === "PENDING");
const isQueued = computed(() => props.task?.status === "QUEUED");
</script>

<style lang="scss" scoped></style>
