<template>
	<div class="modal is-active" id="modal-publish-draft">
		<div class="modal-background" />
		<div class="modal-card">
			<header class="modal-card-head">
				<div class="modal-card-title">
					{{
						$t(
							"views.box.views.configuration.components.modal-publish-draft.title"
						)
					}}
				</div>
				<button
					type="button"
					class="delete"
					aria-label="close"
					@click="close"
				/>
			</header>
			<section class="modal-card-body">
				<label class="label">{{
					$t(
						"views.box.views.configuration.components.modal-publish-draft.comment"
					)
				}}</label>
				<textarea
					class="textarea"
					v-model="notes"
					:placeholder="
						$t(
							'views.box.views.configuration.components.modal-publish-draft.placehoder'
						)
					"
					rows="10"
				/>
			</section>
			<footer class="modal-card-foot">
				<ws-button is-primary is-outlined @click="close">
					{{ $t("cancel") }}
				</ws-button>
				<ws-button is-primary @click="publish" :disabled="isDemo">
					<template #iconSmall v-if="isDemo">
						<ws-icon icon="lock" />
					</template>
					<span>{{
						$t(
							"views.box.views.configuration.components.modal-publish-draft.send-to-box"
						)
					}}</span>
				</ws-button>
			</footer>
		</div>
	</div>
</template>

<script>
import AuthMixin from "@/mixins/auth.mixin";

export default {
	mixins: [AuthMixin],

	data() {
		return {
			notes: ""
		};
	},

	emits: ["cancel", "publish"],
	methods: {
		close() {
			this.$emit("cancel");
		},

		publish() {
			this.$emit("publish", this.notes);
		}
	}
};
</script>
