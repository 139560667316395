<template>
	<div>
		<p>
			{{ $t("banner.scaling-to-gateway.bacnet-mqtt.body.pending.1") }}
		</p>
		<p>
			{{ $t("banner.scaling-to-gateway.bacnet-mqtt.body.pending.2") }}
		</p>
		<p>
			<i18n-t
				keypath="banner.scaling-to-gateway.bacnet-mqtt.body.pending.3"
				tag="p"
				scope="global"
			>
				<template #downloadBtn>
					<ws-text-link
						is-link
						:show-icon="false"
						@click="emits('downloadProperties')"
					>
						{{
							$t(
								"banner.scaling-to-gateway.bacnet-mqtt.download-properties-btn"
							)
						}}
					</ws-text-link>
				</template>
			</i18n-t>
		</p>
		<p>
			{{ $t("banner.scaling-to-gateway.bacnet-mqtt.body.pending.4") }}
		</p>

		<div class="mt-4 mb-0 buttons">
			<ws-button
				is-grey
				is-small
				is-outlined
				@click="emits('sendConfiguration')"
			>
				{{
					$t(
						"banner.scaling-to-gateway.bacnet-mqtt.send-configuration-btn"
					)
				}}
			</ws-button>
		</div>
	</div>
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emits = defineEmits([
	"downloadProperties",
	"startMigration",
	"revertMigration",
	"sendConfiguration"
]);
</script>
