<template>
	<div
		style="font-size: smaller"
		class="under-navbar"
		v-if="isDeviceRoute && scalingActionRequired"
	>
		<ws-notification-message
			type="info"
			show-icon
			icon="info"
			:show-close-button="false"
		>
			<template #title>
				{{
					isPending
						? $t("banner.scaling-to-gateway.title.pending")
						: $t("banner.scaling-to-gateway.title.queued")
				}}
			</template>
			<banner-modbus
				v-if="hasModbusScalingTask"
				:task="scalingTasks[0]"
				@download-properties="downloadProperties"
				@start-migration="startMigration"
				@revert-migration="revertMigration"
				@send-configuration="publishConfig"
			/>
			<banner-bacnet-mqtt
				v-else
				:task="scalingTasks[0]"
				@download-properties="downloadProperties"
				@start-migration="startMigration"
				@revert-migration="revertMigration"
				@send-configuration="publishConfig"
			/>
		</ws-notification-message>
		<teleport to="#modals">
			<modal-publish-draft
				v-if="showModalPublishDraft"
				@publish="publishDraftRevision"
				@cancel="showModalPublishDraft = false"
			/>
		</teleport>
	</div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useDeviceStore } from "@/store/device";
import { useWsToast } from "@/plugins/toast/toast.plugin.js";
import { useExportBulkConfiguration } from "@/composables/useExportBulkConfiguration";
import EventBus from "@/eventbus.js";
import ConfigsService from "@/services/v1/Configs.service.js";
import CommandsService from "@/services/v1/Commands.service.js";
import helpers from "@/helpers/functions.helper.js";
import BannerBacnetMqtt from "./components/banner-bacnet-mqtt.vue";
import BannerModbus from "./components/banner-modbus.vue";
import ModalPublishDraft from "@/views/box/components/modal-publish-draft.vue";

const { t: $t } = useI18n();
const route = useRoute();
const { go: routerGo } = useRouter();
const { error: $toastError, success: $toastSuccess } = useWsToast();
const { downloadExport: download } = useExportBulkConfiguration({
	selectedExport: "properties"
});

const deviceStore = useDeviceStore();

const showModalPublishDraft = ref(false);

const isDeviceRoute = computed(
	() => route.name.search(/^(box|hub)-v1/gi) !== -1
);

const scalingTasks = computed(() =>
	deviceStore.scalingTasks.filter((task) =>
		["PENDING", "QUEUED"].includes(task.status)
	)
);
const scalingActionRequired = computed(() => scalingTasks.value.length);

// TODO: in the future we should handle multiple migration tasks at once instead of only using [0]
const isPending = computed(
	() => scalingTasks.value && scalingTasks.value[0]?.status === "PENDING"
);

const hasModbusScalingTask = computed(() => {
	return !!scalingTasks.value.find(
		(task) => task.type?.type === "MODBUS_SCALING_MIGRATION"
	)?.id;
});

async function downloadProperties() {
	return await download();
}
async function startMigration() {
	try {
		await deviceStore.queueScalingMigration(scalingTasks.value[0]?.id);
	} catch (error) {
		$toastError("failed queueMigration", error);
	}
}
async function revertMigration() {
	try {
		await deviceStore.dequeueScalingMigration(scalingTasks.value[0]?.id);
	} catch (error) {
		$toastError("failed dequeueMigration", error);
	}
}

async function publishConfig() {
	showModalPublishDraft.value = true;
}
async function trackCommand(commandId, qttRetries = 0) {
	if (qttRetries >= 5) {
		return routerGo();
	}
	const command = await CommandsService.getCommand(commandId);
	if (command.status?.startsWith("ERROR")) {
		const draftConfiguration = await ConfigsService.getDraft();
		if (draftConfiguration.errors) {
			$toastError($t("box-didnt-receive-configuration"));
		}
	} else if (["NEW", "SENT", "RETRY_SEND"].indexOf(command.status) !== -1) {
		await helpers.sleep(5);
		await trackCommand(commandId, qttRetries + 1);
	}
}
async function trackDraftRevision() {
	try {
		await ConfigsService.getDraft();
		await helpers.sleep(5);
		await trackDraftRevision();
	} catch (err) {
		if (err.status === 404) {
			return true;
		}

		throw err;
	}
}
async function makeDraftConfigurationAvailable() {
	try {
		this.draftErrors = [];
		const draftConfiguration = await ConfigsService.getDraft();
		const currentConfiguration = await this.getCurrentConfiguration();
		if (currentConfiguration.errors) {
			$toastError($t("failed-get-draft-config"));
		}
		deviceStore.draftConfig = draftConfiguration;
		deviceStore.currentConfig = currentConfiguration;
	} catch (err) {
		if (err?.status === 404) {
			await deviceStore.createDraftConfig();
			await deviceStore.getCurrentConfiguration();
		} else {
			$toastError($t("failed-get-draft-config"));
		}
	}
}
async function publishDraftRevision(notes = "") {
	try {
		EventBus.$emit("addXhr");
		const responseRevision =
			await ConfigsService.publishDraftRevision(notes);
		if (responseRevision?.errors) {
			$toastError(
				$t(
					"views.box.views.configuration.components.footer-buttons.failed-saving-configuration"
				)
			);
		}

		// Simple changes doesn't require a reboot from Box, so no need for a commandId
		if (responseRevision.commandId) {
			await trackCommand(responseRevision.commandId);
			await trackDraftRevision();
		}
		$toastSuccess(
			$t(
				"views.box.views.configuration.components.footer-buttons.new-configuration-in-box"
			)
		);
		await makeDraftConfigurationAvailable();
		EventBus.$emit("configurationUpdated");
	} catch (err) {
		let errorMessage =
			$t(
				"views.box.views.configuration.components.footer-buttons.failed-saving-configuration"
			) +
			": " +
			(err.data && err.data.message
				? err.data.message
				: JSON.stringify(err));

		if (err instanceof TypeError) {
			errorMessage = err.message;
		}

		$toastError(errorMessage);
	} finally {
		showModalPublishDraft.value = false;
		$toastError(
			$t(
				"views.box.views.configuration.components.footer-buttons.failed-saving-configuration"
			)
		);
		EventBus.$emit("removeXhr");
	}
}
</script>
<style lang="scss" scoped>
.under-navbar {
	padding: 0.5rem 1rem;
	background-color: $color-grey-300;
}

// The Hover of the subtle button has been modified > change the background from grey-200 to 5%transparency of the grey-700
:deep(button.is-grey.is-outlined:hover) {
	background-color: rgba($color-grey-700, 0.05) !important;
}
</style>
